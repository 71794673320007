
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #191919;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-style: normal;
  background: #fff !important;
}

a,
button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

*::-moz-selection {
  background: #dc3545;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #dc3545;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #dc3545;
  color: #fff;
  text-shadow: none;
}

*::-moz-placeholder {
  color: #555555;
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: #555555;
  font-size: 14px;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  transition: .4s;
  -webkit-transition: all .4s ease-in-out;
  border: none !important;
}

a:hover {
  text-decoration: none;
  border: none !important;
}

button:focus {
  outline: none;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

p {
  color: #555;
}

img {
  max-width: 100%;
}

@media (min-width: 1000px) {
  .container {
      max-width: 1000px;
  }
}

.item-text-editor{
  
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 5px;
}

/*
* #-Header
*/
.header {
  background: #dc3545;
  color: #fff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 10px 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0px;
}

.header-home{
  background: transparent;
  color: #292929;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 10px 0px;
  position: absolute;
  width: 100%;
  z-index: 999;
  top: 0px;
}

.navbar-inner .left {
  float: left;
}

.offcanvas-start{
  width: 300px !important;
}

.navbar-inner .left .canvas_open {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff4f;
}

.navbar-inner .left .canvas_open a {
  display: block;
}

.navbar-inner .left .canvas_open a span {
  width: 18px;
  height: 2px;
  background: #fff;
  display: block;
  margin: 5px 0px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease;
}

.navbar-inner .left .canvas_open a span:nth-child(2) {
  width: 12px;
}

.navbar-inner .title {
  float: left;
  overflow: hidden;
  padding-left: 10px;
}

.navbar-inner .title h2 {
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 700;
  padding-top: 6px;
}

.navbar-inner .title h2 a {
  color: #fff;
}

.text-right {
  text-align: right;
}

.navbar-inner .right {
  float: right;
  height: 100%;
  align-items: center;
  display: flex;
}

.navbar-inner .right .wishlist-btn {
  padding-right: 12px;
}

.navbar-inner .right .wishlist-btn a {
  color: #fff;
  font-size: 20px;
}

.navbar-inner .right .bell-btn a {
  color: #fff;
  font-size: 20px;
  position: relative;
}

.navbar-inner .right .bell-btn a span {
  width: 6px;
  height: 6px;
  background: #00ff10;
  display: block;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 1px;
}

/*
* #-Footer Nav
*/
.footer-nav-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 5px 0px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 6px 1px 30px rgba(15, 15, 15, 0.65);
  box-shadow: 6px 1px 30px rgba(15, 15, 15, 0.65);
}

.footer-nav-menu {
  display: flex;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.footer-nav-menu .footMenu {
  text-align: center;
  color: #909090;
  padding: 6px 0px;
  display: inline-block;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease;
  width: 100%;
}

.footer-nav-menu a span {
  font-size: 12px;
}

.footer-nav-menu a i {
  display: block;
  font-size: 20px;
}

.footer-nav-menu a.active {
  color: #dc3545;
  border-bottom: 3px solid #dc3545 !important;
}

.footer-nav-menu a:hover {
  color: #dc3545;
}

/*
* #-Notifications
*/
.notifications-area {
  background: #F7F9FA;
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 10px 16px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow-y: auto;
  margin-left: 0px;
  top: 100%;
  opacity: 0;
  visibility: hidden;
}

.notifications-area.active {
  top: 0;
  visibility: visible;
  opacity: 1;
}

.notification-breadcrumb .left {
  float: left;
}

.notification-breadcrumb {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.notification-breadcrumb {
  border-bottom: 1px solid #eee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 10px;
  background: #F7F9FA;
  z-index: 99;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.notification-breadcrumb .left a {
  font-size: 24px;
  color: #535353;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.notification-breadcrumb .left a:hover {
  color: #dc3545;
}

.notification-breadcrumb .title {
  overflow: hidden;
}

.notification-breadcrumb .title h2 {
  color: #384144;
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  padding-top: 4px;
  text-transform: capitalize;
}

.notifications-content {
  padding-top: 70px;
}

.notification-item {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 15px;
  padding: 12px 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px #d9d9d9;
}

.notification-item .title {
  margin-bottom: 10px;
}

.notification-item .title h2 {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.notification-item .title h2 i {
  font-size: 13px;
}

.notification-item .text {
  margin-bottom: 10px;
}

.notification-item .text p {
  line-height: 22px;
}

.notification-item .item-time {
  width: 100%;
  overflow: hidden;
}

.notification-item .item-time .left {
  float: left;
}

.notification-item .item-time .left span {
  color: #666;
}

/*
* #-Canvas menu
*/
.off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  opacity: 0;
  background: #232323;
  top: 0;
}

.off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.canvas-menu {
  background: #dc3545;
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 10px 16px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow-y: auto;
  margin-left: -300px;
}

.canvas-menu.active {
  margin-left: 0px;
}

.menu-close i {
  color: #fff;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
}

.canvas-header {
  margin-top: 30px;
}

.ba-navbar-user .thumb {
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
}

.ba-navbar-user .thumb img {
  width: 100px;
  border-radius: 50%;
}

.ba-navbar-user .content h3 {
  font-size: 18px;
  text-transform: capitalize;
  line-height: 1.2;
  color: #fff;
  font-weight: 600;
  margin-bottom: 2px;
}

.ba-navbar-user .content p {
  color: #ddd;
  font-size: 14px;
}

.canvas-nav ul li {
  display: block;
}

.canvas-nav ul li a {
  width: 100%;
  display: inline-block;
  color: #ffffff;
  padding-top: .625rem;
  padding-bottom: .625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 14px;
  font-weight: 600;
  border-radius: .375rem;
  margin-bottom: .25rem;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease;
}

.canvas-nav ul li a:hover {
  background: #ffffff1c;
}

.canvas-nav ul li a i {
  padding-right: 4px;
}

/*
* #-Search Area
*/
.search-area {
  background: #F7F9FA;
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 10px 16px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow-y: auto;
  margin-left: 0px;
  top: 100%;
  opacity: 0;
  visibility: hidden;
}

.search-area.active {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.top-to-breadcrumb {
  border-bottom: 1px solid #eee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 10px;
  background: #F7F9FA;
  z-index: 101;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}


.top-to-breadcrumb button{
  border: none;
  border-radius: 50%;
}

.top-to-breadcrumb .left {
  float: left;
}

.top-to-breadcrumb .left a {
  font-size: 24px;
  color: #535353;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.top-to-breadcrumb .left button {
  font-size: 24px;
  color: #535353;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.top-to-breadcrumb .left a:hover {
  color: #dc3545;
}

.top-to-breadcrumb .left button:hover {
  color: #dc3545;
}

.top-to-breadcrumb .title {
  overflow: hidden;
}

.top-to-breadcrumb .title h2 {
  color: #384144;
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  padding-top: 4px;
  text-transform: capitalize;
}


.images-button-editor-content{
  margin: auto;
  width: 100%;
  text-align: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  background: #7a7a7a;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 999px;
  height: 70px;
}

.content-editor-bottom{
  display: flex;
  width: auto;
  margin: auto;
}

.images-button-editor{
  margin-left: 1px;
  color: #fff !important;
  background: #000000 !important;
  border-radius: 3px !important;
}

.text-input-limit-editor{
  position: absolute;
  right: 10px;
  top: 10px;
}

.images-modal-tabs-editor .active{
  background: #dc3545 !important;
  border-radius: 20px !important;
}

.content-full-post-editor-tabs{
  color: #000;
  justify-content: center;
  justify-items: center;
}

.content-editor-blog{
  justify-content: center;
  justify-items: center;
  display: flex;
}

.content-editor-blog-card{
  margin: auto;
  width: 100%;
}

.button-editor-canvas-front{
  display: grid;
  justify-content: center;
  justify-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  z-index: 999;
  border: none;
  background: rgb(207, 207, 207);
  border-radius: 20px;
  width: 70%;
  margin: auto;
  margin-top: 15px;
  padding: 25px;
}

.button-editor-canvas-front:hover{
  background: #dcdcdc;
  -webkit-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
}

.button-editor-canvas-front *{
  margin: auto;
}

.button-change-color-editor{
  -webkit-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  border-radius: 50px;
}

.text-title-editor-row{
  color: #414141;
  padding: 10px;
}

.content-blog-post{
  width: 100%;
  border-radius: 10px;
  
}

.button-close-blog-modal{
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 15px;
  background: transparent;
  border-radius: 50px;
  border: 1px solid #fff;
  background: #000;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
}

.carousel-content-body-gallery{
  -webkit-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
}

.carousel-content-body-gallery img{
  border-radius: 5px;
}

.button-close-blog-modal:hover{
  background: #fff;
  color: #000;
}

.content-1-blog{
  position: absolute;
  width: 100%;
  height: 100%;
}

.content-2-blog{
  width: 100%;
  height: 100%;
  position: relative;
}

.image-blog-main{
  width: 100%;
}


/* opciones de diseño */

.content-option-2{
  -webkit-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  border-radius: 10px;
  padding: 20px;
  color: #292929;
}


.button-show-more-blog{
  margin-top: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
  background: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  padding-left: 40px;
  padding-right: 40px;
}

.button-show-more-blog:hover{
  background: #fff;
  color: #000;
}

.content-buttons-editor{
  position: fixed;
  top: 5px;
  right: 10px;
  z-index: 999;
}

.button-top-editor{
  margin-right: 8px;
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

.content-menu-options-editor{
  background: #fff;
  border-radius: 10px;
  display: grid;
  height: 300px;
  width: 130px !important;
  position: fixed;
  left: 15px;
  bottom: 105px;
}

.button-option-editor-show{
  position: fixed;
  right: 40px;
  top: 10%;
  border-radius: 100px;
  border: none;
  background: #F7CF57;
  width: 73px !important;
  height: 70px;
  -webkit-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
}

.responsiveNavBarTop a{
  padding-right: 15px !important;
  border-radius: 10px;
}

.responsiveNavBarTop a:hover {
  background: #e35362;
  color: #fff;
}

.search-area-full {
  padding-top: 70px;
}

.serach-top-form {
  width: 100%;
  position: relative;
}

.serach-top-form input {
  font-size: 14px;
  background-color: #fff;
  color: #535353;
  border: 1px solid #f1f1f1;
  width: 100%;
  position: relative;
  padding: 14px 20px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.serach-top-form input:focus {
  border-color: #dc3545;
}

.serach-top-form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
  padding: 0px 18px;
  color: #fff;
  font-size: 18px;
  background: #dc3545;
}

.recent-popular-serach h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.search-tags button {
  display: inline-block;
  border: 1px solid #e1e1e1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 5px 12px;
  color: #535353;
  font-size: 12px;
  text-transform: capitalize;
  background: #fff;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.search-tags a {
  display: inline-block;
  border-right: 0.5px solid #e1e1e1 !important;
  margin-left: 5px;
  /* border-radius: 6px; */
  padding: 1px 12px;
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  background: transparent;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  height: 20px;
}

.search-tags a:hover{
  color: #dc3545;
  border-color: #dc3545; 
}

.header-search-offcanva {
  height: 30% !important;
}

.content-page-not-found{
  background: #dc3545;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 16px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 1px 16px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 1px 16px -3px rgba(0,0,0,0.75);
}

.header-search-offcanva .btn-close{
  color: #fff !important;
}

.buttonTabsEditor{
  padding: 10px !important;
  background: #000;
}

.search-tags button:hover {
  color: #dc3545;
  border-color: #dc3545;
}

/*
* #-Live Chart
*/
.chart-area {
  background: #F7F9FA;
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 0px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow-y: auto;
  margin-left: 0px;
  top: 100%;
  opacity: 0;
  visibility: hidden;
}

.chart-area.active {
  top: 0%;
  opacity: 1;
  visibility: visible;
}

.live-chart-header {
  background: #dc3545;
  padding: 10px 16px;
  text-align: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-bottom: 30px;
  padding-top: 85px;
}

.live-chart-header p {
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 4px;
}

.live-chart-header .thumbnail img {
  width: 50px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.live-chart-header .status-online {
  position: relative;
  z-index: 1;
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
  padding-left: 15px;
}

.live-chart-header .status-online::after {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #00b894;
  border-radius: 50%;
  content: "";
  margin-top: -5px;
  top: 50%;
  left: 0;
  z-index: 10;
  -webkit-animation: flashing 1.5s infinite linear;
  animation: flashing 1.5s infinite linear;
}

.type-text-form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
}

.live-chart-header .thumbnail {
  display: flex;
  justify-content: center;
}

.live-chart-header .thumbnail .c-te {
  text-align: left;
  padding-left: 18px;
}

.type-text-form form {
  width: 100%;
  position: relative;
}

.type-text-form form input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #eee;
  height: 50px;
  position: relative;
  padding: 0px 40px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  padding-right: 55px;
}

.type-text-form form .file input {
  height: 20px;
  display: none;
}

.type-text-form form .file i {
  position: absolute;
  top: 12px;
  left: 8px;
  cursor: pointer;
  border: 2px solid #eee;
  font-size: 14px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #666;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.type-text-form form .file i:hover {
  color: #dc3545;
  border-color: #dc3545;
}

.type-text-form form .file label {
  display: flex;
}

.type-text-form form button {
  position: absolute;
  top: 5px;
  right: 10px;
  border: none;
  font-size: 14px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  width: 40px;
  height: 40px;
  background: #dc3545;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.type-text-form form button:hover {
  color: #fff;
}

.live-chart-box-full {
  padding: 0px 14px;
  padding-top: 20px;
  padding-bottom: 60px;
  box-sizing: border-box;
}

.support-message-content {
  margin-bottom: 15px;
}

.user-message-text {
  margin-bottom: 15px;
}

.support-message-content {
  margin-bottom: 15px;
  overflow: hidden;
  width: 100%;
}

.support-message-content .thumb {
  width: 40px;
  float: left;
  padding-right: 10px;
  padding-top: 5px;
}

.support-message-content .text-content {
  overflow: hidden;
  position: relative;
}

.support-message-content .text-content .d-block p {
  margin-bottom: 10px;
  background: #fff;
  padding: 12px 15px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
}

.support-message-content .text-content span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.user-message-text .text-content {
  text-align: right;
}

.user-message-text .text-content span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.user-message-text .text-content .d-block p {
  margin-bottom: 10px;
  background: #dc3545;
  padding: 12px 15px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  color: #fff;
}

.support-message-content .text-content .d-block .writing-mode {
  background: #fff;
  padding: 12px 15px;
  display: inline-block;
  border-radius: 10px;
}

.support-message-content .text-content .d-block .writing-mode .dot {
  width: 7px;
  height: 7px;
  display: inline-block;
  background: #acaaaa;
  margin: 0px 1px;
  border-radius: 50%;

  -webkit-animation: wave 1.3s linear infinite;
  animation: wave 1.3s linear infinite;
}

.support-message-content .text-content .d-block .writing-mode .dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.support-message-content .text-content .d-block .writing-mode .dot:nth-child(3) {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

@-webkit-keyframes wave {

  0%,
  100%,
  60% {
      -webkit-transform: initial;
      transform: initial;
  }

  30% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
  }
}

@keyframes wave {

  0%,
  100%,
  60% {
      -webkit-transform: initial;
      transform: initial;
  }

  30% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
  }
}

/*
* #-Cart
*/
.cart-wrapper {
  background: #F7F9FA;
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 10px 16px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow-y: auto;
  margin-left: 0px;
  top: 100%;
  opacity: 0;
  visibility: hidden;
}

.cart-wrapper.active {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.cart-single-p {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  -webkit-box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
  box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
}

.sombras-tv-card{
  -webkit-box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
  box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
}

.cart-single-p .content {
  overflow: hidden;
}

.quantity {
  position: relative;
  width: 100px;
  display: block;
}

.cart-single-p .thumb {
  width: 120px;
  margin-right: 15px;
  float: left;
}

.quantity input {
  width: 100%;
  text-align: center;
  padding-left: 0;
  -moz-appearance: textfield;
  height: 30px;
  border-radius: 0px;
  border: 1px solid #eee;
  font-weight: 600;
}

.quantity-nav {
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.quantity-button.quantity-up {
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  display: flex;
  width: 25px;
  background: #535353;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: all .4s ease;
}

.quantity-button.quantity-down {
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  display: flex;
  width: 25px;
  background: #535353;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: all .4s ease;
}

.cart-single-p .content .left h4 {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 4px;
}

.cart-single-p .content .left h4 a {
  color: #535353;
}

.cart-single-p .content .left h4:hover a {
  color: #dc3545;
}

.content-design-editor{
  width: 100%;
  background: #dadada;
  text-align: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 30px;
}
.content-design-editor:hover{
  -webkit-box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
  box-shadow: 1px 4px 17px -4px rgba(0,0,0,0.75);
}

.links-blogs-bottom{
  position: absolute;
  bottom: 5%;
  left: 0px;
  width: 100%;
  z-index: 99px;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-top: 5px;
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 10px;
}

.links-blogs-list-vertical{
  max-height: 400px;
  overflow-y: auto;
}

.link-a-social-blog{
  background: transparent;
  color: #3c3c3c;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #2f1111 !important;
  padding: 5px;
  text-align: center;
  
}

.link-a-social-blog:hover{
  background: #fff;
  border: 1px solid #2f1111 !important;
  color: #000;
}

.item-social-blog-list{
 padding-right: 10px;
}

.input-search-home{
  background: transparent;
  border: 1px solid #f2f2f2;
  border-radius: 60px;
  padding-top: 13px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  color: #f2f2f2;
}

.button-search-input-home{
  position: absolute;
  right: 10px;
  height: 100%;
  top: 83px;
}

.button-search-input-home-navbar{
  position: absolute;
  right: 10px;
  height: 100%;
  top: 25px;
}


.input-search-home::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #f2f2f2;
  opacity: 1; /* Firefox */
}

.input-search-home:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #f2f2f2;
}

.input-search-home::-ms-input-placeholder { /* Microsoft Edge */
  color: #f2f2f2;
}

.cart-single-p .content .price {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #dc3545;
}

.c-p-calendar {
  position: absolute;
  top: 53%;
  /* right: 80px; */
  right: 12px;
  width: 95px;
  height: 25px;
  background: #c59a0e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  border-radius: 8px;
  cursor: pointer;
  font-size: 17px;
}


.c-p-edit {
  position: absolute;
  top: 75%;
  right: 12px;
  width: 85px;
  height: 25px;
  background: #5c74ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  cursor: pointer;
  font-size: 17px;
}

.c-p-proyectar {
  position: absolute;
  top: 75%;
  right: 112px;
  width: 110px;
  height: 25px;
  background: #c59a0e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  border-radius: 8px;
  cursor: pointer;
  font-size: 17px;
}

.textInfoPublication{
  color: #eaeaea;
  z-index: 99999;
  position: fixed;
  left: 10px;
  bottom: 2px;
  font-size: 18px;
  text-shadow: -1px 1px 0 #080808, -3px 2px 10px #1b1e33;
}

.text-shadow-blog{
  text-shadow: 0px 0px 35px rgba(0,0,0,0.49);
}

.tagbutton{
  cursor: pointer;
}

.c-p-remove {
  position: absolute;
  top: 5%;
  right: 12px;
  width: 25px;
  height: 25px;
  background: #dc3545;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 17px;
}


.c-p-check-post {
  position: absolute;
  top: 5%;
  right: 12px;
  width: 110px;
  height: 25px;
  background: #44a305;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5%;
  cursor: pointer;
  font-size: 17px;
}

.c-p-inactive-post {
  position: absolute;
  top: 5%;
  right: 12px;
  width: 110px;
  height: 25px;
  background: #a30532;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5%;
  cursor: pointer;
  font-size: 17px;
}

.table-row-pending-post{
  background: rgb(163, 145, 29);
  color: #fff;
}
.table-row-pending-post a{
  color: #fff;
}
.table-row-pending-post span{
  color: #fff;
}

.content-update-status-button{
  margin: auto;
  text-align: right;
  position: fixed;
  bottom: 10px;
  right: 20px;
  bottom: 20px;
  width: 100%;
  z-index: 999;
}

.content-update-status-button button{
  border: none;
  border-radius: 15px;
  padding: 15px;
  background: #F7CF57;
  color: #000;
  -webkit-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.75);
}

.c-p-promotion-text{
  position: absolute;
  top: 78%;
  right: 12px;
  width: 130px;
  height: 25px;
  background: #c59a0e;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding-left: 5px;
  cursor: pointer;
}


.c-p-pending-approval-admin{
  color: #e52e06;
  font-size: 13px;
  position: absolute;
  right: 2%;
  bottom: 3%;
  width: 100px;
}


.c-p-promotion {
  position: absolute;
  top: 78%;
  right: 12px;
  width: 25px;
  height: 25px;
  background: #c59a0e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  font-size: 17px;
}

.cuppon-form {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
}

.cuppon-form h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px;
}

.cuppon-form p {
  font-size: 13px;
  margin-bottom: 10px;
}

.cuppon-form form {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.cuppon-form form input {
  width: 100%;
  border: 1px solid #eee;
  padding: 12px 16px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.cuppon-form form input:focus {
  border-color: #dc3545;
}

.cuppon-form form button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  height: 100%;
  background: #dc3545;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 15px;
}

.cart-checkout-p-area {
  background: #fff;
  border-radius: 5px;
  padding: 20px 10px;
}

.cart-checkout-p-area .left .total span {
  font-size: 14px;
  font-weight: 600;
}

.cart-checkout-p-area .left .total span b {
  color: #dc3545;
}

.cart-checkout-p-area .right a {
  display: inline-block;
  padding: 10px 20px;
  background: #dc3545;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
}

/*
* #-Profile
*/
.profile-wrapper {
  background: #F7F9FA;
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 10px 16px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow-y: auto;
  margin-left: 0px;
  top: 100%;
  opacity: 0;
  visibility: hidden;
}

.profile-wrapper.active {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.profile-wrapper-content-header {
  background: #dc3545;
  padding: 30px 20px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}

.profile-name-or-u span {
  color: #fff;
  font-weight: 500;
}

.profile-name-or-u p {
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
}

.profile-wrapper-content-c {
  background: #fff;
  padding: 20px;
  border-radius: 30px;
}

.single-profile-data {
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
}

.single-profile-data .title i {
  display: inline-block;
  background: #dc3545;
  color: #fff;
  border-radius: 6px;
  text-align: center;
  width: 26px;
  height: 26px;
  line-height: 1.9;
  font-size: 12px;
  padding-top: 1px;
  margin-right: 2px;
}

.single-profile-data .title {
  float: left;
}

.single-profile-data .data-content {
  overflow: hidden;
  text-align: right;
}

.single-profile-data .title span {
  font-weight: 600;
  font-size: 12px;
}

.single-profile-data .data-content p {
  font-size: 12px;
}

.single-profile-data .data-content a {
  display: inline-block;
  background: #F61957;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 16px;
  border-radius: 3px;
}

.edit-p-btn a {
  width: 100%;
  text-align: center;
  display: inline-block;
  background: #dc3545;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0px;
  border-radius: 4px;
}

.edit-p-btn a i {
  padding-left: 4px;
}

.edit-profile-pic label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #535353;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
}

.edit-profile-pic label input {
  opacity: 0;
  display: none;
}

.edit-profile-header .profile-img {
  position: relative;
}

.edit-profile-pic {
  position: absolute;
  top: 0;
  left: 0;
}

.edit-profile-wrapper {
  padding: 0px 15px;
}

.edit-pautty-form label {
  width: 100%;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.edit-pautty-form input {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #eee;
  color: #535353;
  padding: 12px 15px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.edit-pautty-form input:focus {
  border-color: #dc3545;
}


.edit-pautty-form textarea {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #eee;
  color: #535353;
  padding: 12px 15px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  min-height: 100px;
  max-height: 300px;
}

.edit-pautty-form textarea:focus {
  border-color: #dc3545;
}

.edit-pautty-form button {
  width: 100%;
  text-align: center;
  display: inline-block;
  background: #dc3545;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0px;
  border-radius: 4px;
  border: none;
}

.order-page-area {
  background: #F7F9FA;
  box-sizing: border-box;
  padding: 0px 15px;
}

.order-item-single {
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
  background: #fff;
  padding: 10px;
}

.order-item-single .thumb {
  float: left;
  width: 80px;
  margin-right: 10px;
}

.order-item-single .right {
  overflow: hidden;
}

.order-item-single .right h4 {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.order-item-single .right p {
  font-weight: 600;
  color: #dc3545;
  padding-bottom: 8px;
}

.order-item-single .right .btan span:nth-child(1) {
  padding: 2px 10px;
  display: inline-block;
  border-radius: 4px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 12px;
}

.order-item-single .right .btan span:nth-child(2) {
  text-align: right;
  float: right;
  color: #555;
  font-size: 12px;
}

.hero-slider-area {
  box-sizing: border-box;
  height: 100%;
}

.hero-slider-single {
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 70px 20px;
  height: 100%;
}


/*Animation*/
.active .hero-slider-single h2,
.active .hero-slider-single h4,
.active .hero-slider-single a {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.active .hero-slider-single h4 {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.active .hero-slider-single a {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.hero-slider-caption h2 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
  color: #fff;
  margin-bottom: 2px;
}

.hero-slider-caption h4 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 5px;
}

.hero-slider-caption a {
  display: inline-block;
  background: #dc3545;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 15px;
  border-radius: 4px;
}

.hero-slider-full {
  position: relative;
}

.content-username-slides {
  width: 100%;
}

.hero-slider-full .owl-dots {
  width: 100%;
  text-align: center;
}

.hero-slider-full .owl-dots .owl-dot {
  margin: 0 6px;
  border: 0;
  background: none;
  cursor: pointer;
}

.hero-slider-full .owl-dots .owl-dot.active span {
  background: #dc3545;
}

.hero-slider-full .owl-dots .owl-dot span {
  display: block;
  border-radius: 50%;
  background-color: #fff;
  width: 8px;
  height: 8px;
  position: relative;
  transition: all 0.3s ease;
}

.hero-slider-full .owl-dots .owl-dot span::after {
  position: absolute;
  content: "";
  top: -5px;
  left: -5px;
  border: 1px solid #dc3545;
  border-radius: 50%;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  transform: scale(0);
  transition: all 0.3s ease;
}

.hero-slider-full .owl-dots .owl-dot.active span::after {
  transform: scale(1);
}

.category-slider {
  box-sizing: border-box;
  padding: 10px;
}

.category-slider h2 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #535353;
}

.cate-s-item {
  background: #fff;
  width: 90%;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  padding: 20px 10px;
}

.cate-s-item a {
  text-align: center;
  display: inline-block;
  color: #535353;
}

.cate-s-item a i {
  font-size: 30px;
  display: block;
  margin-bottom: 2px;
}

.cate-s-item a span {
  font-size: 12px;
  text-transform: capitalize;
  color: #666;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease;
}

.cate-s-item a:hover span {
  color: #dc3545;
}

.cate-s-item .fa-heart {
  color: #ff2929;
}

.cate-s-item .fa-gamepad {
  color: #1c90f4;
}

.cate-s-item .fa-mobile-alt {
  color: #c6384c;
}

.cate-s-item .fa-tshirt {
  color: green;
}

.top-prodcut-area .row {
  width: 100%;
}

.top-prodcut-area {
  box-sizing: border-box;
  padding: 10px;
}

.top-prodcut-area h2 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #535353;
}

.product-item {
  width: 100%;
  background: #fff !important;
  border-radius: 5px;
  position: relative;
  padding-bottom: 10px;
}

.product-item .thumbnail .p-wish-icon {
  position: absolute;
  top: 5px;
  right: 6px;
}

.product-item .thumbnail {
  position: relative;
  margin-bottom: 10px;
}

img {
  max-width: 100%;
}

.product-item .thumbnail .p-wish-icon a {
  color: #dc3545;
  font-size: 18px;
}

.product-item .thumbnail .p-wish-icon a:hover {
  color: #D82E6E;
}

.product-item .content h4.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.product-item .content h4.title a {
  color: #535353;
}

.product-item .content h4.title:hover a {
  color: #dc3545;
}

.product-item .content .price {
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 14px;
}

.product-item .content .price del {
  font-size: 12px;
  color: #dc3545;
  padding-left: 4px;
}

.ratting span {
  float: left;
  font-size: 10px;
  color: #ff6536;
}

.product-item .cart-btn {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #F7CF57;
  color: #000;
  margin-right: 12px;
}

.product-item .col-5 {
  padding: 0;
}

.top-product-slider .product-item {
  width: 96%;
}

.banner-inner {
  width: 93%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 55px 20px;
}

.banner-inner h2 {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.banner-inner a {
  display: inline-block;
  padding: 8px 20px;
  background: #F7CF57;
  color: #000;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 20px;
}

.feature-product {
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
  overflow: hidden;
  background: #fff;
}

.sombras-card{
  -webkit-box-shadow: 3px 4px 18px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 3px 4px 18px -15px rgba(0,0,0,0.75);
  box-shadow: 3px 4px 18px -15px rgba(0,0,0,0.75);
}

.buttonsItemCard {
  padding: 5px;
  background: #fff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  -webkit-box-shadow: 3px 4px 18px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 3px 4px 18px -15px rgba(0,0,0,0.75);
  box-shadow: 3px 4px 18px -15px rgba(0,0,0,0.75);
}

.buttonsItemCard a{
  color: #5e5e5e;
  font-size: 13px;
}

.buttonsItemCard span{
  color: #5e5e5e;
  font-size: 13px;
}

.sombras-title-1{
  color: #fff;
  position: absolute;
  width: 100%;
  font-size: 180%;
}

.feature-product h2 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #535353;
}

.button-12 {
  display: inline-block;
  padding: 10px 30px;
  background: #dc3545;
  text-transform: capitalize;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
}

.button-12:hover {
  color: #fff;
}

/*===== All Preloader Style =====*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
}

#preloader .preloader {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  margin-left: -25px;
  margin-top: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#preloader .preloader span {
  position: absolute;
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: #e52e06;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}

#preloader .preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
  background-color: green;
}

.itemDesignPost{
  margin-top: 15px;
  border: none;
}

@keyframes preloader {
  0% {
      transform: scale(0, 0);
      opacity: 0.5;
  }

  100% {
      transform: scale(1, 1);
      opacity: 0;
  }
}

@-webkit-keyframes preloader {
  0% {
      -webkit-transform: scale(0, 0);
      opacity: 0.5;
  }

  100% {
      -webkit-transform: scale(1, 1);
      opacity: 0;
  }
}

/*End Preloader Style*/
.p-loading-mode .dot {
  width: 7px;
  height: 7px;
  display: inline-block;
  background: #dc3545;
  margin: 0px 1px;
  border-radius: 50%;

  -webkit-animation: wave 1.3s linear infinite;
  animation: wave 1.3s linear infinite;
}

.p-loading-mode .dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.p-loading-mode .dot:nth-child(3) {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

@-webkit-keyframes wave {

  0%,
  100%,
  60% {
      -webkit-transform: initial;
      transform: initial;
  }

  30% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
  }
}

@keyframes wave {

  0%,
  100%,
  60% {
      -webkit-transform: initial;
      transform: initial;
  }

  30% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
  }
}

.all-lefr-or-r {
  width: 100%;
  overflow: hidden;
}

.feature-product .left {
  float: left;
}

.feature-product .right {
  float: right;
  padding-right: 10px;
}

.product-filer-left i {
  font-size: 20px;
  cursor: pointer;
}

.p-product-filer {
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  bottom: 0;
  z-index: 9999;
  box-sizing: border-box;
  padding: 10px 16px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow-y: auto;
  margin-left: -300px;
  background: #F7F9FA;
  padding-top: 20px;
}

.p-product-filer.active {
  margin-left: 0px;
}

.p-product-filer .menu-close i {
  color: #535353;
}

.single-p-product-filer {
  margin-bottom: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}

.single-p-product-filer h3 {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.single-p-product-filer input {
  width: 14px;
  height: 14px;
  border-radius: 0px;
  border: 1px solid #eee;
}

.single-p-product-filer label {
  color: #666;
}

.product-details-thumbnail .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}

.product-details-thumbnail .owl-dots .owl-dot {
  margin: 0 6px;
  border: 0;
  background: none;
  cursor: pointer;
}

.product-details-thumbnail .owl-dots .owl-dot.active span {
  background: #dc3545;
}

.product-details-thumbnail .owl-dots .owl-dot span {
  display: block;
  border-radius: 50%;
  background-color: #000;
  width: 8px;
  height: 8px;
  position: relative;
  transition: all 0.3s ease;
}

.product-details-thumbnail .owl-dots .owl-dot span::after {
  position: absolute;
  content: "";
  top: -5px;
  left: -5px;
  border: 1px solid #dc3545;
  border-radius: 50%;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  transform: scale(0);
  transition: all 0.3s ease;
}

.product-details-thumbnail .owl-dots .owl-dot.active span::after {
  transform: scale(1);
}

.p-details-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}

.p-details-bottom a {
  width: 100%;
  display: inline-block;
  background: #F7CF57;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 0px;
  font-size: 20px;
}


/* modulo de pago de publicación */

.capa-pagos{
  position: fixed;
  z-index: 9999;
  background: #e3e3e3;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0.97;
  text-align: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  padding-top: 10%;
  overflow-y: scroll;
}

.content-capa-pagos {
  margin: auto;
  width: 90%;
  height: 60%;
}


.priceContent{
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  width: 100%;
  margin: 3px;
  height: 100%;
  padding: 10px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.75);
}

.carousel-pauta-content{
  color: #fff;
  text-shadow: 1px 1px 17px black;
}

.priceContent hr{
  color: #b3b3b3;
}

.closeModalPaymentWompi{
  border: none;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9999;
  background: transparent;
}

.text-terms-payment{
  color: #aeaeae;
  width: 100%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  padding-top: 5px;
}

/* .priceContent button{
  bottom: 5px;
  position: relative;
  border: none;
  border-radius: 5px;
  background: #F7CF57;
  color: #000;
  padding: 8px;
} */

/* .waybox-button{
  position: fixed;
  z-index: 99999;
  top: 20px;
  right: 20px;
} */

.p-details-bottom a i {
  padding-right: 4px;
}

.p-d-f-content {
  box-sizing: border-box;
  padding: 15px 15px;
}

.p-d-f-content .title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.price-or-quantity {
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}

.price-or-quantity .quantity {
  float: left;
  margin-right: 15px;
}

.price-or-quantity .price {
  overflow: hidden;
}

.price-or-quantity .price span {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.price-or-quantity .price span del {
  font-size: 16px;
  color: #dc3545;
}

.p-d-f-content .ratting {
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  padding-top: 10px;
}

.p-d-f-content .ratting span {
  font-size: 14px;
}

.p-d-f-content .ratting span small {
  color: #535353;
}

.color-details-full h4 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.color--details .single {
  float: left;
  margin: 0px 3px;
}

.color--details .single span {
  width: 15px;
  height: 15px;
  display: block;
  background: red;
}

.color--details .single span.green {
  background: green;
}

.color--details .single span.blue {
  background: blue;
}

.color--details .single span.yellow {
  background: yellow;
}

.size-details-page {
  width: 100%;
  overflow: hidden;
  padding-top: 12px;
  margin-bottom: 20px;
}

.size-details-page h4 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.size-details-page .form-check {
  float: left;
  margin-right: 10px;
  color: #535353;
}

.p-d-f-content .dec h4 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.p-d-f-content .dec p {
  text-align: justify;
  line-height: 26px;
  margin-bottom: 15px;
}

.share-product span {
  margin: 0px 2px;
}

.share-product a {
  display: inline-block;
}

.share-product a i {
  font-size: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #084f99;
  border-radius: 5px;
  color: #fff;
}

.share-product a i.fa-twitter {
  background: #1da1f2;
}

.share-product a i.fa-linkedin {
  background: #0e76a8;
}

.share-product a i.fa-skype {
  background: green;
}

.share-product a i.fab.fa-instagram {
  background: radial-gradient(circle farthest-corner at 32% 106%, #ffe17d 0, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), linear-gradient(135deg, #234bd7 12%, #c33cbe 58%);
}

.rattingl-list {
  margin-top: 12px;
  box-sizing: border-box;
  padding: 0px 13px;
}

.rattingl-list h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.rattingl-list li {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  padding-left: 50px;
}

.rattingl-list li .thumbnail {
  width: 32px;
  position: absolute;
  left: 0;
}

.rattingl-list li .ratting {
  width: 100%;
  overflow: hidden;
}

.rattingl-list li .ratting span {
  font-size: 14px;
  margin: 0px 2px;
}

.rating-form {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
}

.rating-form h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.rating-form .ratting {
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}

.rating-form .ratting span {
  font-size: 20px;
}

.rating-form form {
  width: 100%;
}

.rating-form form textarea {
  width: 100%;
  border: 1px solid #eee;
  padding: 13px 14px;
  height: 100px;
  margin-bottom: 15px;
}

.rating-form form button {
  background: #dc3545;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 20px;
}

.wishlist-page .product-item .thumbnail .p-wish-icon a {
  color: #fff;
  font-size: 16px;
  background: #dc3545;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.contact-area {
  box-sizing: border-box;
  padding: 10px 15px;
}

.contact-info .single {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #d9d7d7;
  display: flex;
}

.contact-info .single .icon {
  font-size: 25px;
  margin-right: 10px;
  color: #dc3545;
  padding-top: 5px;
}

.contact-info .single .content {
  overflow: hidden;
}

.contact-info .single .content p {
  font-size: 16px;
  font-weight: 600;
}

.contact-form {
  margin-bottom: 20px;
  background: #fff;
  padding: 20px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #d9d7d7;
}

.contact-form h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.contact-form input {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #eee;
  padding: 14px 15px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.contact-form textarea {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #eee;
  padding: 14px 15px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  height: 120px;
}

.contact-form input:focus {
  border-color: #dc3545;
}

.contact-form textarea:focus {
  border-color: #dc3545;
}

.contact-form button {
  margin-bottom: 20px;
  border: none;
  background: #dc3545;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 30px;
}

.checkout-form {
  padding: 10px 14px;
  padding-top: 75px;
  width: 100%;
  box-sizing: border-box;
}

.checkout-form label {
  width: 100%;
  font-weight: 500;
  text-transform: capitalize;
  color: #666;
  padding-bottom: 2px;
}

.checkout-form input {
  width: 100%;
  border: 1px solid #eee;
  padding: 10px 12px;
  margin-bottom: 15px;
}

.checkout-form select {
  width: 100%;
  border: 1px solid #eee;
  padding: 10px 12px;
  margin-bottom: 15px;
}

.checkout-form textarea {
  width: 100%;
  border: 1px solid #eee;
  padding: 10px 12px;
  margin-bottom: 15px;
  height: 100px;
}

.checkout-form button {
  margin-bottom: 30px;
  padding: 12px 40px;
  text-transform: uppercase;
  font-weight: 600;
  background: #dc3545;
  color: #fff;
  border: none;
  letter-spacing: 1px;
}

.setting-area-full {
  padding: 0px 15px;
  padding-top: 75px;
  padding-bottom: 35px;
}

.setting-item {
  width: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
  padding: 14px 15px;
  box-shadow: 0px 0px 10px 0px #ececec;
  margin-bottom: 10px;
}

.setting-item .title {
  float: left;
}

.setting-item .title i {
  color: #dc3545;
  font-size: 20px;
  padding-right: 6px;
  float: left;
}

.setting-item .title span {
  overflow: hidden;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  color: #666;
}

.setting-item .date-content-t {
  float: right;
}

.setting-item .toggle-button-cover {
  position: relative;
  z-index: 1;
}

.setting-item .button.r,
.setting-item .button.r .layer {
  border-radius: 100px;
}

.setting-item .button.r {
  position: relative;
  width: 45px;
  height: 21px;
  overflow: hidden;
  border: 1px solid #ebebeb;
}

.setting-item .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.setting-item .knobs::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  background-color: #ea4c62;
  border-radius: 50%;
  -webkit-transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.setting-item .layer {
  width: 100%;
  background-color: transparent;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  z-index: 1;
}

.setting-item .knobs {
  z-index: 2;
}

.setting-item .knobs,
.setting-item .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.setting-item .checkbox:checked+.knobs::before {
  content: '';
  left: 24px;
  background-color: #100DD1;
}

.login-form-dmark {
  margin: 15px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-register-form-head {
  width: 100%;
  overflow: hidden;
  margin-bottom: 35px;
}

.login-register-form-middle .single-input {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
}

.login-register-form-middle .single-input input {
  width: 100%;
  position: relative;
  border: 1px solid #eee;
  padding: 15px 20px;
}

.login-register-form-middle .single-input label {
  position: absolute;
  top: -10px;
  background: #fff;
  color: #000;
  z-index: 1;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  left: 20px;
}

.login-register-form-middle .single-input label.rememberme {
  position: relative;
  top: -7px;
  left: 10px;
}

.login-register-form-middle .single-input input#rememberme {
  width: auto;
  float: left;
  border-radius: 0px;
}

.login-register-form-middle .single-input button {
  width: 100%;
}

.single-input.checkbox {
  margin-bottom: 10px;
}

.login-register-from-btom p {
  font-size: 14px;
  color: #535353;
}

.login-register-from-btom p a {
  color: #535353;
}

.login-register-from-btom p a:hover {
  color: #5f3afc;
}

.login-register-from-btom p.text-dark a {
  padding-left: 6px;
}

.login-register-form-middle .single-input button {
  width: 100%;
  background: #dc3545;
  color: #ffff;
  text-transform: uppercase;
  padding: 14px 0px;
  font-weight: 600;
  border: none;
}

.login-register-form-head h2 {
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-weight: 700;
}

.login-register-form-head h3 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 900;
}

.login-register-form-head h3 a {
  color: #535353;
}

.login-register-form-head ul li {
  display: inline-block;
  text-align: center;
  width: 23%;
  margin: 0% 1%;
}

.login-register-form-head ul li.google {
  width: 48%;
}

.login-register-form-head ul li.facebook a {
  width: 100%;
  display: block;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 5px;
  padding: 13px 0px;
  background: #36528C;
}

.login-register-form-head ul li.twitter a {
  width: 100%;
  display: block;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 5px;
  padding: 13px 0px;
  background: #0D8DDC;
}

.login-register-form-head ul li.google a {
  background: #D04237;
  width: 100%;
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 5px;
  padding: 15px 0px;
}

.login-register-form-head ul li.google a i {
  font-size: 20px;
}

.login-register-form-head ul li.google a span {
  padding-left: 6px;
}

.content-login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background-image: url('https://statics-gridwel.s3.us-east-2.amazonaws.com/img/japan-2014616_960_720.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}



.input-form-pautty{
  border-radius: 30px;
}


.login-form {
  border-radius: 15px;
  /* background-image: linear-gradient(to right top, #f52d3e, #f52d3e, #f52d3e, #f73d4c, rgb(250, 128, 128)); */
  z-index: 99999;
  padding-top: 15px;
  padding-bottom: 15px;
}

.text-login-title {
  font-size: 60px;
  color: #dc3545;
}

.form-control-whatsapp {
  border: none;
  padding: 10px !important;
  background: transparent;
  margin-left: 10px;
  padding-left: 15px !important;
  border-radius: 30px !important;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
  margin-bottom: 5px;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload{
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border: 2px solid #808080;
  /* border-style: dashed; */
}

.custom-file-upload:hover{
  background: rgb(218, 218, 218);
}

.title-login-options{
  font-size: 35px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}







.color-bottons {
  background: #F7CF57 !important;
  color: #292929 !important;
  border-radius: 30px !important;
}

.buttons-style-editor{
  border: none;
  background: #F7CF57;
  color: #292929;
  border-radius: 50%;
  padding: 5px;
  width: 40px;
  height: 40px;
}

.button-plus-center{
  background: #fff;
  position: absolute;
  top: -44%;
  left: 42%;
  border-radius: 50px;
  padding: 5px;
  border: none;
  z-index: 1201;
}

.background-menu-center{
  position: absolute;
  top: 0px;
  width: 100%;
  height: 800vh;
  background: rgba(0, 0, 0, 0.798);
  z-index: 998;
}

.modal-options-button-center{
  background: #eaeaea;
  width: 100%;
  height: 100vh;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  z-index: 1100;
  position: absolute;
  top: -400%;
  left: 0px;
  display: block;
  justify-content: center;
  text-align: center;
  justify-items: center;
}

.btn-primary{
  border-color: none !important;
}

.text-color-pauty {
  color: #dc3545;
}

.heartLikeScreen {
  position: absolute;
  float: right;
  right: 20px;
  top: 15px;
}

.heartLikeScreen a {
  color: #dc3545;
  font-size: 20px;
}

.datepicker-dropdown {
  padding: 15px;
}

.datepicker td,
.datepicker th {
  width: 40px;
  height: 30px;
  font-size: 18px;
}

.centerMaxContentPage {
  max-width: 900px;
  justify-content: center;
  justify-items: center;
  margin: auto;
  background: #fff;
}


.centerMaxContentPageSlides {
  max-width: 600px;
  justify-content: center;
  justify-items: center;
  margin: auto;
}

.maxWidthContentGeneral{
  max-width: 800px;
  margin: auto;
}

.paddingTopButtonBack{
  padding-top: 70px;
}

a{
  text-decoration: none !important;
}
.contentIndexNavBar{
  padding-top: 66px;
  max-width: 1600px;
  margin: auto;
  background: #f52d3e0c;
}

.product-all{
  margin: auto;
}

.contentScreenShowClients{
  background: rgb(238,222,222);
  background: linear-gradient(284deg, rgba(238,222,222,2)35%, rgba(255,255,255,1)100%);
}

.contentModalLocation{
  text-align: right;
  position: absolute;
   bottom: 0px;
   right: 0px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
}
/**
* ==============================================
* Dot Stretching
* ==============================================
*/
.dot-stretching {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
  color: #dc3545;
  transform: scale(1.25, 1.25);
  animation: dotStretching 2s infinite ease-in;
}

.dot-stretching::before,
.dot-stretching::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-stretching::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
  color: #dc3545;
  animation: dotStretchingBefore 2s infinite ease-in;
}

.dot-stretching::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
  color: #dc3545;
  animation: dotStretchingAfter 1s infinite ease-in;
}

.section-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
  line-height: 1;
}

.notification-area {
  position: relative;
  z-index: 1;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem;
}

.notification-area .list-group-item:first-child {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.notification-area .list-group-item {
  border-color: #ebebeb;
  margin-bottom: 0.5rem;
  border-radius: 6px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: .5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
}

.notification-area .list-group-item h6 {
  font-size: 14px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  color: #020310;
}

.notification-area .list-group-item span {
  font-size: 12px;
  display: block;
  color: #747794;
}

::-webkit-scrollbar{
  height: 0px;
  background:  #e6e6e6;
  width: 7px;
}
::-webkit-scrollbar-thumb{
  background: #8f8f8f;
  border-radius: 10px;
}

.text-preview-screen{
  font-weight: 80;
  text-shadow: -1px 1px 0 #080808, -3px 2px 10px #1b1e33;
}

.input-editor-text{
  border: 1px solid #bbbaba;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 10px !important;
  padding: 10px;
  width: 100% !important;
}

.text-preview-screen-dark{
  font-weight: 80;
}

.text-preview-screen-inferior{
  text-shadow: -1px 1px 0 #080808, -3px 2px 10px #1b1e33;
}

.fondo-preview-screen {
  margin: 'auto';
  border-radius: 3;
  position: 'relative';
  -webkit-box-shadow: 0px 3px 15px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 15px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 15px -2px rgba(0,0,0,0.75);
}

.item-list-mypost{
  -webkit-box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.75);
}

@keyframes dotStretching {
  0% {
      transform: scale(1.25, 1.25);
  }

  50%,
  60% {
      transform: scale(0.8, 0.8);
  }

  100% {
      transform: scale(1.25, 1.25);
  }
}

@keyframes dotStretchingBefore {
  0% {
      transform: translate(0) scale(0.7, 0.7);
  }

  50%,
  60% {
      transform: translate(-20px) scale(1, 1);
  }

  100% {
      transform: translate(0) scale(0.7, 0.7);
  }
}

@keyframes dotStretchingAfter {
  0% {
      transform: translate(0) scale(0.7, 0.7);
  }

  50%,
  60% {
      transform: translate(20px) scale(1, 1);
  }

  100% {
      transform: translate(0) scale(0.7, 0.7);
  }
}


.button-create-post{
  border-radius: 15px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  -webkit-box-shadow: 1px 3px 8px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 1px 3px 8px 0px rgba(0,0,0,0.4);
  box-shadow: 1px 3px 8px 0px rgba(0,0,0,0.4);
}

.buttonResponsiveCreatePost{
  bottom: 13%;
  position: fixed;
  right: 6%;
  z-index: 999;
}

.datePickerModal{
  background: #dc3545;
  padding: 20px;
  color: transparent;
  z-index: 99999;
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #dc3545;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.buttoncardpautta {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  color: #dc3545;
  background: transparent;
  border: 1px solid #dc3545;
  border-radius: 5px;
  z-index: 99;
}

.buttoncardpautta:hover{
  background: #dc3545;
  color: #ffffff;
}

.marker {
  background: #dc3545 !important;
}

.nav-link{
  color: #97999c !important;
}

.nav-tabs .nav-link{
  border: 2px solid transparent !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border-color: #fff #fff #fff !important;
  color: #fff !important;
}

.center-tabs-screen{
  justify-content: center;
  justify-items: center;
  margin: auto;
}

.preview-post-fixed-0{
  display: none;
}

.image-preview-post{
  display: flex;
}


/*--------------------------------------- Links social media */
.social-links-e-1{
  background: #fff;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  border-radius: 50px;
  border: 1px solid #d7d7d7;
  height: 60px;
}

.item-social-blog{
  margin: auto;
  display: inline-block;
  -webkit-box-shadow: 1px 0px 21px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 0px 21px -4px rgba(0,0,0,0.75);
  box-shadow: 1px 0px 21px -4px rgba(0,0,0,0.75);
  border-radius: 50px;
  padding: 7px;
  margin-left: 5px;
}



/*--------------------------------------- RESPONSIVE */
@media (min-width: 100px) {
  .responsiveNavBarFooter{
    display: block;
  }
  .responsiveNavBarTop{
    display: none;
  }
  .buttonResponsiveCreatePost{
    bottom: 13%;
  }
}


@media (min-width: 300px) {
  .responsiveNavBarFooter{
    display: block;
  }
  .responsiveNavBarTop{
    display: none;
  }
  .buttonResponsiveCreatePost{
    bottom: 13%;
  }
}

@media (min-width: 576px) {
  .responsiveNavBarFooter{
    display: block;
  }
  .responsiveNavBarTop{
    display: none;
  }
  .buttonResponsiveCreatePost{
    bottom: 13%;
  }
  
}

@media (min-width: 768px) {
  .responsiveNavBarFooter{
    display: none;
  }
  .responsiveNavBarTop{
    display: block;
  }
  .buttonResponsiveCreatePost{
    bottom: 5%;
  }
  .content-styles-tv{
    height: 85vh !important;
  }
  .header-search-offcanva {
    height: 18% !important;
  }
}

@media (min-width: 992px) {
  .responsiveNavBarFooter{
    display: none;
  }
  .responsiveNavBarTop{
    display: block;
  }
  .preview-post-fixed-1{
    position: fixed;
    max-width: 450px !important;
    margin-left: 0px;
  }
  .image-preview-post{
    display: inline-block;
  }

  .preview-post-fixed-0{
    display: inline-block;
  }

  .button-search-input-home{
    top: 93px;
  }
  .header-search-offcanva {
    height: 32% !important;
  }
  
}

@media (min-width: 1200px) {
  .responsiveNavBarFooter{
    display: none;
  }
  .responsiveNavBarTop{
    display: block;
  }
  .header-search-offcanva {
    height: 30% !important;
  }
}



/* Landing Page Pautty */

/********** Template CSS **********/
:root {
  --primary: #dc3545;
  --secondary: #ff737f;
  --light: #F7FAFF;
  --dark: #1D1D27;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Heading ***/
h1,
h2,
h3,
.fw-bold {
  font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
  font-weight: 500 !important;
}


/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}


/*** Navbar ***/
.navbar-light .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  color: var(--light) !important;
  outline: none;
  transition: .5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: #fff !important;
}

.navbar-light .navbar-brand h1 {
  color: #FFFFFF;
}

.navbar-light .navbar-brand img {
  max-height: 60px;
  transition: .5s;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 45px;
}

.navbar{
  background: #dc3545 !important;
  color: #fff;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-light {
      position: relative;
      background: #FFFFFF;
  }

  .navbar-light .navbar-collapse {
      margin-top: 15px;
      border-top: 1px solid #DDDDDD;
  }

  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
      padding: 10px 0;
      margin-left: 0;
      color: #fff !important;
  }

  .navbar-light .navbar-brand h1 {
      color: #ffffff;
  }

  .navbar-light .navbar-brand img {
      max-height: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-light {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      border-bottom: 1px solid rgba(256, 256, 256, .1);
      z-index: 999;
  }
  
  .sticky-top.navbar-light {
      position: fixed;
      background: #FFFFFF;
  }

  .navbar-light .navbar-nav .nav-link::before {
      position: absolute;
      content: "";
      width: 0;
      height: 4px;
      bottom: -1px;
      left: 50%;
      background: #fff;
      transition: .5s;
  }

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
      width: 100%;
      left: 0;
  }

  .navbar-light .navbar-nav .nav-link.nav-contact::before {
      display: none;
  }

  .sticky-top.navbar-light .navbar-brand h1 {
      color: #fff;
  }
}


/*** Hero Header ***/
.hero-header {
  background:
      url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/bg-dot.png),
      url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/bg-dot.png),
      url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/bg-round.png),
      url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/bg-tree.png),
      url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/bg-bottom-hero.png);
  background-position:
      10px 10px,
      bottom 190px right 10px,
      left 55% top -1px,
      left 45% bottom -1px,
      center bottom -1px;
  background-repeat: no-repeat;
}


/*** Section Title ***/
.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 4px;
  bottom: 0;
  left: 0;
  background: var(--dark);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  bottom: 0;
  left: 50px;
  background: var(--dark);
}

.section-title.text-center::before {
  left: 50%;
  margin-left: -25px;
}

.section-title.text-center::after {
  left: 50%;
  margin-left: 25px;
}

.section-title h6::before,
.section-title h6::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 2px;
  left: 0;
  background: rgba(33, 66, 177, .5);
}

.section-title h6::after {
  top: 5px;
  left: 3px;
}


/*** Service ***/
.service-item {
  position: relative;
  height: 350px;
  padding: 30px 25px;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .5s;
}

.service-item:hover {
  background: #dc3545;
}

.service-item .service-icon {
  margin: 0 auto 20px auto;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  background: url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/icon-shape-primary.png) center center no-repeat;
  transition: .5s;
}

.service-item:hover .service-icon {
  color: #dc3545;
  background: url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/icon-shape-white.png);
}

.service-item h5,
.service-item p {
  transition: .5s;
}

.service-item:hover h5,
.service-item:hover p {
  color: var(--light);
}

.service-item a.btn {
  position: relative;
  display: flex;
  color: #dc3545;
  transition: .5s;
  z-index: 1;
}

.service-item:hover a.btn {
  color: #dc3545;
}

.service-item a.btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  border-radius: 35px;
  background: #DDDDDD;
  transition: .5s;
  z-index: -1;
}

.service-item:hover a.btn::before {
  width: 100%;
  background: var(--light);
}


/*** Testimonial ***/
.newsletter,
.testimonial {
  background:
      url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/bg-top.png),
      url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/bg-bottom.png);
  background-position:
      left top,
      right bottom;
  background-repeat: no-repeat;
}

.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--light) !important;
  border-color: var(--light);
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: #888888;
}

.testimonial-carousel .owl-item.center .testimonial-item i {
  color: #dc3545 !important;
}

.testimonial-carousel .owl-item.center .testimonial-item h6 {
  color: var(--dark) !important;
}


/*** Team ***/
.team-item {
  position: relative;
  transition: .5s;
  z-index: 1;
}

.team-item::after {
  position: absolute;
  content: "";
  top: 3rem;
  right: 3rem;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .1);
  transition: .5s;
  z-index: -1;
}

.team-item:hover::after {
  background: #dc3545;
}

.team-item h5,
.team-item small {
  transition: .5s;
}

.team-item:hover h5,
.team-item:hover small {
  color: var(--light);
}


/*** Project Portfolio ***/
#portfolio-flters .btn {
  position: relative;
  display: inline-block;
  margin: 10px 4px 0 4px;
  transition: .5s;
}

#portfolio-flters .btn::after {
  position: absolute;
  content: "";
  right: -1px;
  bottom: -1px;
  border-left: 20px solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 50px solid #FFFFFF;
}

#portfolio-flters .btn:hover,
#portfolio-flters .btn.active {
  color: var(--light);
  background: #dc3545;
}

.portfolio-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  top: 0;
  left: 0;
  background: #dc3545;
  transition: .5s;
  z-index: 1;
  opacity: 0;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
}

.portfolio-item .btn {
  position: absolute;
  width: 90px;
  height: 90px;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/icon-shape-white.png) center center no-repeat;
  border: none;
  transition: .5s;
  opacity: 0;
  z-index: 2;
}

.portfolio-item:hover .btn {
  opacity: 1;
  transition-delay: .15s;
}


/*** Footer ***/
.footer {
  background: url(https://statics-gridwel.s3.us-east-2.amazonaws.com/img/landingpage/footer.png) center center no-repeat;
  background-size: contain;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid rgba(256, 256, 256, .1);
  border-radius: 40px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: #dc3545;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
